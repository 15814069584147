/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    Card_mobile_image1: file(relativePath: { eq: "sanitair/sanitair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_mobile_image: file(relativePath: { eq: "sanitair/sanitair-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_desktop_image: file(
      relativePath: { eq: "sanitair/sanitair-4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Werkzaamheden aan uw sanitaire voorzieningen`,
  textWithLink: () => (
    <>
      <p>
        Tegenwoordig is de keuze op het gebied van sanitaire installaties erg
        groot. Van zeer eenvoudige tot luxueuze douches, ligbaden, wastafels en
        toiletvoorzieningen. Bij ons kunt u terecht voor al uw werkzaamheden aan
        uw sanitaire voorzieningen. Zo kunt u de volledige installatie en
        reparatie met een gerust hart aan ons overlaten.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/sanitair'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Sanitair = ({ data, path, location }) => {
  const seo = {
    title: `Sanitair`,
    description: `Bij ons kunt u terecht voor al uw werkzaamheden aan uw sanitaire voorzieningen.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.Card_mobile_image1.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Functioneel en mooi sanitair',
    textWithLink: () => (
      <>
        <p>
          Met een passend ligbad of een masserende douchevoorziening, kunt u een
          heerlijk relaxt gevoel krijgen. Deze en vele andere sanitaire
          voorzieningen kunnen uw leven zeer veraangenamen. Een goed sanitair is
          immers een belangrijk onderdeel in huis, omdat u er dagelijks gebruik
          van maakt. Naast dat het functioneel dient te zijn, is het uiteraard
          ook belangrijk dat uw sanitair er mooi uitziet. Wij bekijken graag
          samen met u hoe wij uw sanitair kunnen inrichten op de manier zoals u
          dat wilt.
        </p>
        <p>
          Neem{' '}
          <AniLink fade to='/contact'>
            contact
          </AniLink>{' '}
          met ons op en laat u adviseren over uw sanitaire voorzieningen.
        </p>
      </>
    ),
  };

  const sources = [
    data.Ribbon_mobile_image.childImageSharp.fluid,
    {
      ...data.Ribbon_desktop_image.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw sanitair`,
    pitchLine: `Laat uw sanitair over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Sanitair.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Sanitair;
